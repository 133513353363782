﻿﻿﻿

var $ = jQuery.noConflict();











$(document).ready(function () {
  (function($, window) {
    var $window = $(window);

    $.fn.lazyload = function(options) {
      var elements = this;
      var $container;
      var settings = {
        threshold       : 0,
        failure_limit   : 0,
        event           : "scroll",
        effect          : "show",
        container       : window,
        data_attribute  : "original",
        skip_invisible  : true,
        appear          : null,
        load            : null
      };

      function update() {
        var counter = 0;

        elements.each(function() {
          var $this = $(this);
          if (settings.skip_invisible && !$this.is(":visible")) {
            return;
          }
          if ($.abovethetop(this, settings) ||
            $.leftofbegin(this, settings)) {
            /* Nothing. */
          } else if (!$.belowthefold(this, settings) &&
            !$.rightoffold(this, settings)) {
            $this.trigger("appear");
          } else {
            if (++counter > settings.failure_limit) {
              return false;
            }
          }
        });

      }

      if(options) {
        /* Maintain BC for a couple of versions. */
        if (undefined !== options.failurelimit) {
          options.failure_limit = options.failurelimit;
          delete options.failurelimit;
        }
        if (undefined !== options.effectspeed) {
          options.effect_speed = options.effectspeed;
          delete options.effectspeed;
        }

        $.extend(settings, options);
      }

      /* Cache container as jQuery as object. */
      $container = (settings.container === undefined ||
      settings.container === window) ? $window : $(settings.container);

      /* Fire one scroll event per scroll. Not one scroll event per image. */
      if (0 === settings.event.indexOf("scroll")) {
        $container.bind(settings.event, function(event) {
          return update();
        });
      }

      this.each(function() {
        var self = this;
        var $self = $(self);

        self.loaded = false;

        /* When appear is triggered load original image. */
        $self.one("appear", function() {
          if (!this.loaded) {
            if (settings.appear) {
              var elements_left = elements.length;
              settings.appear.call(self, elements_left, settings);
            }
            var loadImgUri;
            if($self.data("background"))
              loadImgUri = $self.data("background");
            else
              loadImgUri  = $self.data(settings.data_attribute);

            $("<img />")
              .bind("load", function() {
                $self
                  .hide();
                if($self.data("background")){
                  $self.css('backgroundImage', 'url('+$self.data("background")+')');
                }else
                  $self.attr("src", $self.data(settings.data_attribute))

                $self[settings.effect](settings.effect_speed);

                self.loaded = true;

                /* Remove image from array so it is not looped next time. */
                var temp = $.grep(elements, function(element) {
                  return !element.loaded;
                });
                elements = $(temp);

                if (settings.load) {
                  var elements_left = elements.length;
                  settings.load.call(self, elements_left, settings);
                }
              })
              .attr("src", loadImgUri );
          }

        });

        /* When wanted event is triggered load original image */
        /* by triggering appear.                              */
        if (0 !== settings.event.indexOf("scroll")) {
          $self.bind(settings.event, function(event) {
            if (!self.loaded) {
              $self.trigger("appear");
            }
          });
        }
      });

      /* Check if something appears when window is resized. */
      $window.bind("resize", function(event) {
        update();
      });

      /* Force initial check if images should appear. */
      update();

      return this;
    };

    /* Convenience methods in jQuery namespace.           */
    /* Use as  $.belowthefold(element, {threshold : 100, container : window}) */

    $.belowthefold = function(element, settings) {
      var fold;

      if (settings.container === undefined || settings.container === window) {
        fold = $window.height() + $window.scrollTop();
      } else {
        fold = $(settings.container).offset().top + $(settings.container).height();
      }

      return fold <= $(element).offset().top - settings.threshold;
    };

    $.rightoffold = function(element, settings) {
      var fold;

      if (settings.container === undefined || settings.container === window) {
        fold = $window.width() + $window.scrollLeft();
      } else {
        fold = $(settings.container).offset().left + $(settings.container).width();
      }

      return fold <= $(element).offset().left - settings.threshold;
    };

    $.abovethetop = function(element, settings) {
      var fold;

      if (settings.container === undefined || settings.container === window) {
        fold = $window.scrollTop();
      } else {
        fold = $(settings.container).offset().top;
      }

      return fold >= $(element).offset().top + settings.threshold  + $(element).height();
    };

    $.leftofbegin = function(element, settings) {
      var fold;

      if (settings.container === undefined || settings.container === window) {
        fold = $window.scrollLeft();
      } else {
        fold = $(settings.container).offset().left;
      }

      return fold >= $(element).offset().left + settings.threshold + $(element).width();
    };

    $.inviewport = function(element, settings) {
      return !$.rightofscreen(element, settings) && !$.leftofscreen(element, settings) &&
        !$.belowthefold(element, settings) && !$.abovethetop(element, settings);
    };

    /* Custom selectors for your convenience.   */
    /* Use as $("img:below-the-fold").something() */

    $.extend($.expr[':'], {
      "below-the-fold" : function(a) { return $.belowthefold(a, {threshold : 0}); },
      "above-the-top"  : function(a) { return !$.belowthefold(a, {threshold : 0}); },
      "right-of-screen": function(a) { return $.rightoffold(a, {threshold : 0}); },
      "left-of-screen" : function(a) { return !$.rightoffold(a, {threshold : 0}); },
      "in-viewport"    : function(a) { return !$.inviewport(a, {threshold : 0}); },
      /* Maintain BC for couple of versions. */
      "above-the-fold" : function(a) { return !$.belowthefold(a, {threshold : 0}); },
      "right-of-fold"  : function(a) { return $.rightoffold(a, {threshold : 0}); },
      "left-of-fold"   : function(a) { return !$.rightoffold(a, {threshold : 0}); }
    });

  })(jQuery, window);
})






$('#headerlogo').hide();
$(window).scroll(function () {


  $(".ab_bg").lazyload();
  $(".pro_bg").lazyload();
  $(".pro_bg1").lazyload();
  $(".csr_bg").lazyload();
  $(".cont_bg1").lazyload();
  $(".ind_bg").lazyload();


  $('#head_lg').show();
  if ($(this).scrollTop() > 370 && $(this).scrollTop() < 6850) {
    $('#headerlogo').show();
    $('#divlogo1').hide();
  } else {
    $('#headerlogo').hide();
    $('#divlogo1').show();
  }
});

$(function () {
  var link = $('.border_row a.bo_menu ');

  link.on('click', function (e) {
    var target = $($(this).attr('href'));
    $('html, body').animate({
      scrollTop: target.offset().top
    }, 600);
    $(this).addClass('active');
    e.preventDefault();
  });

  $(window).on('scroll', function () {
    scrNav();
  });

  function scrNav() {
    var sTop = $(window).scrollTop();
    $('section').each(function () {
      var id = $(this).attr('id'),
        offset = $(this).offset().top - 1,
        height = $(this).height();
      if (sTop >= offset && sTop < offset + height) {
        link.removeClass('active');
        $('.border_row').find('[data-scroll="' + id + '"]').addClass('active');
      }
    });
  }

  scrNav();
});


var btn = $('#button');

btn.on('click', function (e) {
  e.preventDefault();
  $('html, body').animate({scrollTop: 0}, '300');
});


$(document).on('click', '#button', function (e) {
  e.preventDefault();
  $('html, body').animate({scrollTop: 0}, '300');
});
$(document).ready(function(){
  if ($(window).width() > 960) {
    $('body').addClass('slide-hidden');
  }
});

$(document).ready(function(){
  $('body').removeClass('slide-hidden');
})
function openSideNav() {

  $('body').toggleClass('slide-hidden');
if($('body').hasClass('slide-hidden')){
  $("#sidebar-wrapper").css('width', '80px');
  $("#content-wrapper").css('padding', '82px 0px 0px 80px');
}
else{
  $("#sidebar-wrapper").css('width', '0px');
  $("#content-wrapper").css('padding', '82px 0px 0px 0px');
}

  // if (!document.getElementById("sidebar-wrapper").style.width) {

  //   document.getElementById("sidebar-wrapper").style.width = "80px";
  //   document.getElementById("content-wrapper").style.padding = "82px 0px 0px 0px";
  //   document.getElementById("content-wrapper").style.transition = "1s";
  //   // document.getElementById("search_fixed").style.width = "98.5%";
  //   // document.getElementById("search_fixed").style.transition = "1s";
  // } else {
  //   if (document.getElementById("sidebar-wrapper").style.width == '80px') {
  //     document.getElementById("sidebar-wrapper").style.width = "0px";
  //     document.getElementById("content-wrapper").style.padding = "82px 0px 0px 0px";
  //     document.getElementById("content-wrapper").style.transition = "1s";
  //     // document.getElementById("search_fixed").style.width = "98.5%";
  //     document.getElementById("search_fixed").style.transition = "1s";
  //   }
  //   else {
  //     document.getElementById("sidebar-wrapper").style.width = "80px";
  //     document.getElementById("sidebar-wrapper").style.transition = "1s";
  //     document.getElementById("content-wrapper").style.padding = "82px 0px 0px 80px";
  //     // document.getElementById("search_fixed").style.width = "95%";
  //     document.getElementById("search_fixed").style.transition = "1s";
  //     document.getElementById("search_fixed").style.margin = "0px 0px 0px 0px";
  //   }
  // }
  /* 1600 screen search view */
  // if (window.matchMedia("(min-width: 1920px)").matches) {
  //   if (!document.getElementById("sidebar-wrapper").style.width) {
  //
  //     document.getElementById("search_fixed").style.width = "95.85%";
  //   } else {
  //     if (document.getElementById("sidebar-wrapper").style.width == '80px') {
  //       document.getElementById("search_fixed").style.width = "95.85%";
  //     }
  //     else {
  //       document.getElementById("search_fixed").style.width = "99.2%";
  //     }
  //   }
  // }
  // else if (window.matchMedia("(min-width: 1600px)").matches) {
  //   if (!document.getElementById("sidebar-wrapper").style.width) {
  //     document.getElementById("search_fixed").style.width = "95%";
  //   } else {
  //     if (document.getElementById("sidebar-wrapper").style.width == '80px') {
  //       document.getElementById("search_fixed").style.width = "95%";
  //     }
  //     else {
  //       document.getElementById("search_fixed").style.width = "99.05%";
  //     }
  //   }
  // }
  // else if (window.matchMedia("(min-width: 1440px)").matches) {
  //   if (!document.getElementById("sidebar-wrapper").style.width) {
  //     document.getElementById("search_fixed").style.width = "94.5%";
  //   } else {
  //     if (document.getElementById("sidebar-wrapper").style.width == '80px') {
  //       document.getElementById("search_fixed").style.width = "94.5%";
  //     }
  //     else {
  //       document.getElementById("search_fixed").style.width = "99%";
  //     }
  //   }
  // }
  // else if (window.matchMedia("(min-width: 1366px)").matches) {
  //   if (!document.getElementById("sidebar-wrapper").style.width) {
  //
  //     document.getElementById("search_fixed").style.width = "94.18%";
  //   } else {
  //     if (document.getElementById("sidebar-wrapper").style.width == '80px') {
  //       document.getElementById("search_fixed").style.width = "94.18%";
  //     }
  //     else {
  //       document.getElementById("search_fixed").style.width = "98.9%";
  //     }
  //   }
  // }
  // else if (window.matchMedia("(min-width: 1280px)").matches) {
  //   if (!document.getElementById("sidebar-wrapper").style.width) {
  //
  //     document.getElementById("search_fixed").style.width = "93.78%";
  //   } else {
  //     if (document.getElementById("sidebar-wrapper").style.width == '80px') {
  //       document.getElementById("search_fixed").style.width = "93.78%";
  //     }
  //     else {
  //       document.getElementById("search_fixed").style.width = "98.8%";
  //     }
  //   }
  // }
  // else if (window.matchMedia("(min-width: 1024px)").matches) {
  //   if (!document.getElementById("sidebar-wrapper").style.width) {
  //
  //     document.getElementById("search_fixed").style.width = "92.2%";
  //   } else {
  //     if (document.getElementById("sidebar-wrapper").style.width == '80px') {
  //       document.getElementById("search_fixed").style.width = "92.2%";
  //     }
  //     else {
  //       document.getElementById("search_fixed").style.width = "98.5%";
  //     }
  //   }
  // } else {
  // }
}


/* Set the width of the side navigation to 0 */
function closeNav() {
  // document.getElementById("sidebar-wrapper").style.width = "0";
  // document.getElementById("content-wrapper").style.padding = "80px 15px 0px 15px";
  // document.getElementById("content-wrapper").style.transition = "2s";
}
// $(window).on('load', function () {
//   $("#cover").fadeOut(1750);
// });


function open_modal() {
  setTimeout(function(){
    $('body').addClass('modal-open');
  }, 350);
}


// admin tab control
